import http from "@/utils/http";
import { objectToUrlParams } from "@/utils/url";

// 获取团队信息以及成员
export function getTeamInfo(params) {
  return http({
    url: "rest/v5/team/members",
    method: "get",
    withCredentials: false,
    params: params
  });
}

// 获取团队信息以及成员
export function changeTeamName(params) {
  return http({
    url: "rest/v5/team/modify",
    method: "post",
    withCredentials: false,
    data: objectToUrlParams(params)
  });
}

// 注册成员
export function addMembers(params) {
  return http({
    url: "rest/v5/team/register/member/prepare",
    method: "post",
    withCredentials: false,
    data: objectToUrlParams(params)
  });
}


// 查询团队成员冻结记录
export function findMemberRecoed(memberId) {
  return http({
    url: `rest/v5/team/member/${memberId}/change/record`,
    method: "get",
    withCredentials: false,
  });
}

// 冻结团队成员
export function freezeMember(memberId) {
  return http({
    url: `rest/v5/team/freeze/member/${memberId}`,
    method: "post",
    withCredentials: false,
  });
}

// 解冻团队成员
export function defreezeMember(memberId) {
  return http({
    url: `rest/v5/team/defreeze/member/${memberId}`,
    method: "post",
    withCredentials: false,
  });
}

// 通知列表
export function teamNotification(params) {
  return http({
    url: `rest/v5/team/messages`,
    method: "get",
    withCredentials: false,
    params: params
  });
}

// 删除通知列表
export function deleteNotification(messageId) {
  return http({
    url: `rest/v5/team/message/${messageId}`,
    method: "delete",
    withCredentials: false,
  });
}

// 获取通知详情
export function getNotification(messageId) {
  return http({
    url: `rest/v5/team/message/${messageId}`,
    method: "get",
    withCredentials: false,
  });
}

// 发送团队通知
export function sendNotification(params) {
  return http({
    url: `rest/v5/team/message`,
    method: "put",
    withCredentials: false,
    data: params
  });
}


// 创建域名
export function createDomain(data) {
  return http({
    url: `rest/v5/team/domain`,
    method: "put",
    withCredentials: false,
    data: objectToUrlParams(data)
  });
}

// 域名是否存在
export function domainExists(domain) {
  return http({
    url: `rest/v5/team/domain/exists`,
    method: "get",
    withCredentials: false,
    params: {
      domain: domain
    }
  });
}

// 校验域名
export function verificationDomain() {
  return http({
    url: `rest/v5/team/domain/record/verification`,
    method: "post",
    withCredentials: false,
  });
}

//放弃校验
export function abandonVerificationDomain() {
  return http({
    url: `rest/v5/team/domain/verification/stop`,
    method: "post",
    withCredentials: false,
  });
} 

// 获取域名验证记录
export function getDomainRecord() {
  return http({
    url: `rest/v5/team/domain/record`,
    method: "get",
    withCredentials: false,
  });
}

// 未绑定邮箱的团队成员列表
export function getUnbindEmailMemberList(params) {
  return http({
    url: `rest/v5/team/members/un_bind_email`,
    method: "get",
    withCredentials: false,
    params: params
  });
}

// 为团队成员绑定新邮箱(只能绑定一个)
export function bindNewEmailForMember(params) {
  return http({
    url: `rest/v5/team/email/bind`,
    method: "post",
    withCredentials: false,
    params: params
  });
}

// 解绑成员邮箱、
export function unbindEmailForMember(params) {
  return http({
    url: `rest/v5/team/email/bind/cancel`,
    method: "post",
    withCredentials: false,
    params: params
  });
}

// 设置完成步骤
export function setStep(params) {
  return http({
    url: `rest/v5/team/mail/setStep`,
    method: "post",
    withCredentials: false,
    params: params
  });
}

// 管理员开启转移任务
export function startTransferTask(data) {
  return http({
    url: `rest/v5/team/mail/create_migrate`,
    method: "post",
    withCredentials: false,
    data: data
  });
}

// 管理员查询邮箱转移任务
export function getTransferTask() {
  return http({
    url: `rest/v5/team/mail/migrate_detail`,
    method: "get",
    withCredentials: false,
  });
}

// 管理员修改邮箱转移任务
export function updateTransferTask(params) {
  return http({
    url: `rest/v5/team/mail/migrate/status`,
    method: "post",
    withCredentials: false,
    params: params
  });
}

// 管理员重新开启邮箱转移任务
export function openTransferTask(params) {
  return http({
    url: `rest/v5/team/mail/migrate/open`,
    method: "post",
    withCredentials: false,
    params: params
  });
}

// 查询邮件迁移结果
export function queryTransferResult(params) {
  return http({
    url: `rest/v5/team/mail/migrate/result`,
    method: "get",
    withCredentials: false,
    params: params
  });
}

// 转移邮件数据
export function transferMail(params) {
  return http({
    url: `rest/v5/team/mail/migrate`,
    method: "post",
    withCredentials: false,
    params: params
  });
}


