var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "teamNotificationDialog",
      attrs: {
        visible: _vm.showNotificationDialog,
        width: "745px",
        "modal-append-to-body": true,
        "close-on-click-modal": false,
        "before-close": _vm.closeNotificationDialog,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showNotificationDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ],
          staticClass: "dialogBody",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "#FFFFFF"
          }
        },
        [
          _vm.sender
            ? _c("div", { staticClass: "senderBox" }, [
                _c("div", { staticClass: "itemBody" }, [
                  _c(
                    "div",
                    { staticClass: "itemAvatar" },
                    [
                      _c("Avatar", {
                        attrs: {
                          path: _vm.sender.profilePhoto,
                          international:
                            _vm.sender.country == ""
                              ? 0
                              : _vm.toNumber(_vm.sender.country)
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "itemContent" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.sender.name) +
                          "\n          "
                      )
                    ]),
                    _c("div", { staticClass: "id" }, [
                      _vm._v(_vm._s(_vm.createTime))
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm.receivers.length !== 0 && _vm.showReceivers
            ? _c("div", { staticClass: "receiversBox" }, [
                _c("div", { staticClass: "sendIcon" }, [
                  _c("span", { staticClass: "iconfont_Me icon-send iconStyle" })
                ]),
                _c("div", { staticClass: "receiversScroll" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.avatarListWidth > 630,
                          expression: "avatarListWidth > 630"
                        }
                      ],
                      staticClass: "direction direction_left",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.changePosition("left")
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont_Me icon-left iconStyle"
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.avatarListWidth > 630,
                          expression: "avatarListWidth > 630"
                        }
                      ],
                      staticClass: "direction direction_right",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.changePosition("right")
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont_Me icon-right iconStyle"
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "avatarList",
                      style: {
                        transform: `translate3d(${_vm.avatarListPosition}px,0,0)`,
                        width: `${_vm.avatarListWidth}px`
                      }
                    },
                    _vm._l(_vm.receivers, function(receiver) {
                      return _c(
                        "div",
                        { key: receiver.providerId, staticClass: "avatar" },
                        [
                          _c("Avatar", {
                            attrs: {
                              path: receiver.profilePhoto,
                              international:
                                receiver.country == ""
                                  ? 0
                                  : _vm.toNumber(receiver.country),
                              isFrozen: receiver.freezeStatus
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              ])
            : _vm._e(),
          _vm.title !== ""
            ? _c("div", { staticClass: "notificationTitle" }, [
                _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
              ])
            : _vm._e(),
          _vm.notificationContent
            ? _c(
                "div",
                {
                  staticClass: "notificationContent",
                  style: { "margin-top": _vm.title == "" ? "20px" : "" }
                },
                [
                  _c("DetailsTextDisplay", {
                    attrs: { postInfo: _vm.notificationContent }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.annex
            ? _c(
                "div",
                { staticClass: "notificationAnnex" },
                [_c("DetailsAnnex", { attrs: { postInfo: _vm.annex } })],
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }