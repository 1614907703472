<template>
  <el-dialog
    :visible.sync="showNotificationDialog"
    width="745px"
    :modal-append-to-body="true"
    class="teamNotificationDialog"
    :close-on-click-modal="false"
    :before-close="closeNotificationDialog"
    :destroy-on-close="true"
  >
    <div
      class="dialogBody"
      v-loading="dialogLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    >
      <div class="senderBox" v-if="sender">
        <div class="itemBody">
          <div class="itemAvatar">
            <Avatar
              :path="sender.profilePhoto"
              :international="
                sender.country == '' ? 0 : toNumber(sender.country)
              "
            ></Avatar>
          </div>
          <div class="itemContent">
            <div class="name">
              {{ sender.name }}
            </div>
            <div class="id">{{ createTime }}</div>
          </div>
        </div>
      </div>
      <div class="receiversBox" v-if="receivers.length !== 0 && showReceivers">
        <div class="sendIcon">
          <span class="iconfont_Me icon-send iconStyle"></span>
        </div>
        <div class="receiversScroll">
          <div
            class="direction direction_left"
            @click.stop="changePosition('left')"
            v-show="avatarListWidth > 630"
          >
            <span class="iconfont_Me icon-left iconStyle"></span>
          </div>
          <div
            class="direction direction_right"
            @click.stop="changePosition('right')"
            v-show="avatarListWidth > 630"
          >
            <span class="iconfont_Me icon-right iconStyle"></span>
          </div>
          <div
            class="avatarList"
            :style="{
              transform: `translate3d(${avatarListPosition}px,0,0)`,
              width: `${avatarListWidth}px`,
            }"
          >
            <div
              class="avatar"
              v-for="receiver in receivers"
              :key="receiver.providerId"
            >
              <Avatar
                :path="receiver.profilePhoto"
                :international="
                  receiver.country == '' ? 0 : toNumber(receiver.country)
                "
                :isFrozen="receiver.freezeStatus"
              ></Avatar>
            </div>
          </div>
        </div>
      </div>
      <div class="notificationTitle" v-if="title !== ''">
        {{ title }}
      </div>
      <div
        class="notificationContent"
        v-if="notificationContent"
        :style="{ 'margin-top': title == '' ? '20px' : '' }"
      >
        <DetailsTextDisplay
          :postInfo="notificationContent"
        ></DetailsTextDisplay>
      </div>
      <div class="notificationAnnex" v-if="annex">
        <DetailsAnnex :postInfo="annex"></DetailsAnnex>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getNotification } from "@/api/newVersion/team";
import Avatar from "../../../components/avatarDisplay/index.vue";
import DetailsTextDisplay from "../../../components/detailsTextDisplay/index.vue";
import DetailsAnnex from "../../postContentDetails/detailsContent/detailsAnnex.vue";
export default {
  name: "TeamNotificationDialog",
  components: {
    Avatar,
    DetailsTextDisplay,
    DetailsAnnex,
  },
  computed: {
    showNotificationDialog() {
      return this.notificationId !== null;
    },
    notificationId() {
      return this.$store.state.team.notificationDialogId;
    },
    sender() {
      const result = this.memberVos.filter((member) => member.identity === 4);
      return result.length !== 0 ? result[0] : null;
    },
    receivers() {
      const result = this.memberVos.filter((member) => member.identity !== 4);
      return result.length !== 0 ? result : [];
    },
    avatarListWidth() {
      return this.receivers.length * 55;
    },
    showReceivers() {
      return this.sender.providerId == this.$store.getters.userInfo.id;
    },
  },
  data() {
    return {
      dialogLoading: false,
      memberVos: [],
      createTime: "",
      avatarListPosition: 6,
      title: "",
      notificationContent: null,
      annex: null,
    };
  },
  watch: {
    avatarListWidth: {
      async handler(val, old) {
        if (val && val !== old) {
          if (this.avatarListPosition < 6) {
            this.changePosition("right");
          }
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getInfo();
  },
  methods: {
    toNumber(str) {
      return parseInt(str);
    },
    async getInfo() {
      if (this.notificationId) {
        this.dialogLoading = true;
        const result = await getNotification(this.notificationId);
        this.dialogLoading = false;
        if (result.code == 200) {
          this.memberVos = result.data.data.memberVos;
          this.createTime = result.data.data.createTime;
          this.title = result.data.data.title;
          try {
            const message = JSON.parse(result.data.data.message);
            this.notificationContent = {
              detail: message.detail,
              richDetail: message.richDetail || "",
            };
            if (message.attachments) {
              this.annex = {
                attachments: message.attachments,
                gigId: "",
              };
            }
          } catch (error) {}
        } else {
          this.$message({
            type: "error",
            message: result.message || result.data.message,
          });
        }
      }
    },
    changePosition(position) {
      switch (position) {
        case "left":
          const left = this.avatarListPosition + 100;
          if (left >= 6) {
            this.avatarListPosition = 6;
          } else {
            this.avatarListPosition = left;
          }
          break;
        case "right":
          const difference = this.avatarListWidth - 630;
          const right = this.avatarListPosition - 100;
          if (this.avatarListWidth <= 630) {
            this.avatarListPosition = 6;
          } else if (-right < difference) {
            this.avatarListPosition = right;
          } else {
            this.avatarListPosition = -difference;
          }
          break;
      }
    },
    closeNotificationDialog() {
      this.$store.commit("setNotificationDialogId", null);
    },
  },
};
</script>
<style lang="stylus" scoped>
.teamNotificationDialog
  .dialogBody
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding 0;
    .senderBox
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .itemBody
        flex 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .itemAvatar
          width: 50px;
          height: 50px;
          overflow hidden;
          flex-shrink: 0;
        .itemContent
          flex 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center
          flex-direction: column;
          box-sizing: border-box;
          padding-left: 10px;
          .name
            height: 24px;
            width: 100%;
            color #333333;
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
          .id
            height: 16px;
            width: 100%;
            text-align: left;
            color: #8F8F8F;
            font-size: 12px;
            line-height: 16px;
            user-select none;
    .receiversBox
      width: 100%;
      height: 58px;
      background: #F2FCF8;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position relative;
      margin-top: 20px;
      .sendIcon
        height: 100%;
        width 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        flex-shrink: 0;
        .iconStyle
          color: #33CC66;
          font-size: 24px;
      .receiversScroll
        flex 1;
        min-width: 0;
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .direction
          width: 22px;
          height: 22px;
          cursor pointer;
          border-radius: 50%;
          position: absolute;
          top: 18px;
          background: rgba(0,0,0,0.5);
          z-index 2;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.2s;
          &:hover
            background: rgba(0,0,0,0.4);
          .iconStyle
            color: #FFFFFF;
            font-size: 16px;
        .direction_left
          left 10px;
        .direction_right
          right 7px;
        .avatarList
          height: 45px;
          white-space: nowrap;
          transition: transform 0.5s;
          .avatar
            width: 45px;
            height: 45px;
            margin-right: 10px;
            display: inline-block;
            position: relative;
    .notificationTitle
      width: 100%;
      font-size: 20px;
      color: #000000;
      text-align: left;
      margin-top: 20px;
      overflow hidden;
    .notificationContent
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 25px;
      overflow hidden;
    .notificationAnnex
      width: 100%;
      overflow hidden;
</style>
<style lang="stylus">
.teamNotificationDialog
  .el-dialog
    margin-top 0 !important;
    padding 30px 40px !important;
    min-height: 500px !important;
    max-height: 550px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .el-dialog__header
      padding 0 !important;
    .el-dialog__body
      padding 9px 0 0 0 !important;
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      overflow-x hidden;
      overflow-y auto;
      &::-webkit-scrollbar
        width: 2px;
      &::-webkit-scrollbar-thumb
        border-radius: 1px;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.2);
        background: rgba(100, 100, 100, 0.2);
      &::-webkit-scrollbar-track
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
</style>
